import App from '../components/App'
import Header from '../components/Header'
import React from 'react'
import SEO from '../atoms/SEO'
import {
  CatalogContextProvider,
  CatalogContextSourceEnum
} from '../organisms/CatalogContextProvider'
import { PsychologistsCatalog } from '../organisms/WizardScreens/MoreOptions'
import { useScrollEffect } from '../atoms/useScrollEffect'

const PageSEO = () => (
  <SEO
    description={`Выбирайте психолога по вашему запросу и проблеме ✔Более 280 психологов ✔Онлайн сессия от 2000 ₽
      ✔Анонимный прием ➨Быстрый подбор специалиста под вашу проблему`}
    images={[
      {
        pathname: '/img/icons/be-in-team/teammate-decor.svg',
        alt: 'catalog'
      }
    ]}
    title="Каталог психологов — база специалистов разных профилей YouTalk"
  />
)

const staticPageContextProps = {
  queryFilters: {
    fullyMatch: false
  },
  page: {
    title: 'Каталог психологов',
    source: CatalogContextSourceEnum.Catalog,
    filterButton: {
      title: 'Фильтры'
    }
  }
}

export default function Catalog({ pageContext }) {
  useScrollEffect()

  const contextProps = {
    state: pageContext.psychologists,
    ...staticPageContextProps
  }

  return (
    <App>
      <PageSEO />
      <Header autoScrollOff />
      <CatalogContextProvider {...contextProps}>
        <PsychologistsCatalog />
      </CatalogContextProvider>
    </App>
  )
}
